.menuContainer * {
    color: #F4F4FC;
    margin: 0px 5px 0px 5px;
}

.contentbody {
    width: auto;
    margin: 12px 10px 0px 10px;
}

@media (max-width:724px) {
    .formContainer {
        width: 100%;
    }  
}

@media (min-width:725px) {
    .formContainer {
        width: 300px;
    }  
}